.container {
  padding-inline: 0px !important;
}
.heroCenter {
  padding-inline: 0px !important;
  padding-block: 130px;
  padding-block-end: 0px;
  position: relative;
  background-image: url(../../Assets/images/home.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.nextLevel {
  padding-block: 130px;
}
.evolution,
.creatingMetaverse,
.trade,
.pulseFi,
.secureEarn,
.contactUs,
.unleash {
  margin-bottom: 200px;
}
.unleash {
  background: url(../../Assets/images/unleashbg.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 1440px;
  margin-inline: auto;
}
@media screen and (max-width: 991px) {
  .evolution,
  .creatingMetaverse,
  .trade,
  .pulseFi,
  .secureEarn,
  .contactUs,
  .unleash {
    margin-bottom: 80px;
  }
}
@media screen and (max-width:768px){
  .creatingMetaverse{
    margin-bottom: 0;
  }
}