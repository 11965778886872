.mainCotainer {
  position: relative;
  z-index: 4;
}
.footer {
  width: 100%;
  height: 95px;
  background-image: url(../../../../Assets/images/TradeEarn.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  padding-block: 84px;
  margin-bottom: 50px;
}

.imageContainer {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}
.imageContainer img {
  width: 100%;
}

.rightContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.rightContainer h1 {
  color: black;
  background: -webkit-linear-gradient(
    264deg,
    #f9e3b0 13.03%,
    #ffc4ab 41.67%,
    #7b8689 87.06%
  );
  -webkit-background-clip: text;
  -webkit-text-stroke: 4px transparent;
  -webkit-text-stroke-width: 0.03em;
  font-weight: bolder;
}
.rightContainer h2 {
  font-weight: 600;
}
.rightContainer p {
  max-width: 606px;
}
.imageContainer {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 991px) {
  .imageContainer img {
    margin: auto;
    max-width: 500px;
  }
  .footer{
    margin: auto;
  }
}
@media screen and (max-width: 576px) {
  .imageContainer img {
    margin: auto;
    max-width: 300px;
  }
  .footer {
    background-image: url(../../../../Assets/images/trade.svg);
  }
}
@media screen and (min-width: 0px) {
  .colOne {
    order: 2;
  }
}
