.footer {
  width: 100%;
  height: 95px;
  background-image: url(../../../../Assets/images/TradeEarn.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
  padding-block: 84px;
}
.mainContainer {
  background: linear-gradient(
    180deg,
    rgba(34, 35, 37, 0.2) 0%,
    rgba(34, 35, 37, 0) 100%
  );
  border: 1px solid #4f4f4f;
  backdrop-filter: blur(6px);
  border-radius: 15px;
  max-width: 1102px;
  max-height: 399px;
  margin-inline: auto;
  display: flex;
  padding-left: 45px;
  gap: 40px;
  margin-top: 180px;
}
.bgLines {
  position: absolute;
  background: url(../../../../Assets/images/whatIsPulseBG.png) center no-repeat;
  background-size: cover;
  width: 370px;
  height: 900px;
  right: 0;
  top: 200px;
}
.imageContainer {
  background: url("../../../../../src/Assets/images/whatIsImage.png") center
    no-repeat;
  background-size: cover;
  width: 366px;
  height: 448px;
  margin-bottom: 0;
  position: relative;
  bottom: 130px;
  border-radius: 15px;
}
.right {
  display: flex;
  flex-direction: column;
  gap: 22px;
  justify-content: center;
}
.right h3 {
  font-weight: 600;
  position: relative;
}
.right p {
  max-width: 583px;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
}
.pinkDots {
  background: url("../../../../../src/Assets/images/pinkDots.png");
  width: 65px;
  height: 73px;
  position: absolute;
  top: -25px;
  right: 30px;
}
.right h3 span {
  background: radial-gradient(
    100% 97% at 100% 50%,
    #f9e3b0 13.03%,
    #ffc4ab 41.67%,
    #ecb4d0 87.06%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: black;
  -webkit-text-stroke: 4px transparent;
  -webkit-text-stroke-width: 0.03em;
}
@media screen and (max-width: 991px) {
  .pinkDots {
    display: none;
  }
  .right p {
    font-size: 18px;
  }
}
@media screen and (max-width: 576px) {
  .footer {
    background-image: url(../../../../Assets/images/trade.svg);
  }
}
@media screen and (max-width: 767px) {
  .mainContainer {
    padding-left: 25px;
  }

  .right p {
    margin-inline: auto;
  }
  .bgLines {
    display: none;
  }
  .mainContainer {
    flex-direction: column-reverse;
    padding-block: 40px 0;
    max-height: none;
    padding-left: 0;
    margin-top: 0px;
  }
  .imageContainer {
    bottom: 0;
    width: 100%;
    height: 300px;
  }
  .right {
    text-align: center;
  }
}
