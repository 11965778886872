.mainContainer {
  height: 994px;
  background: url("../../../../../src/Assets/images/hands-digital-universe-background-2.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  mix-blend-mode: screen;
}
.headings {
  text-align: center;
}
.headings h2 {
  max-width: 733px;
  margin-inline: auto;
  padding-block: 160px 25px;
  font-weight: 600;
}
.headings p {
  max-width: 811px;
  margin-inline: auto;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .headings h2 {
    padding-block: 210px 25px;
  }
}
