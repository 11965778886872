.heading {
  display: flex;
  justify-content: space-between;
  margin-bottom: 65px;
}
.heading h2 {
  max-width: 481px;
  font-weight: 600;
}
.heading p {
  max-width: 479px;
}
.left {
  border-radius: 15px;
  background: url("../../../../../src/Assets/images/newwaycoins.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  max-width: 594px;
  margin-inline: auto;
}
.cardContainer {
  position: relative;
  max-width: 272px;
}
.card {
  clip-path: polygon(85% 0%, 100% 15%, 100% 100%, 0% 100%, 0% 0%);
  width: 100%;
  border-radius: 20px;
  position: relative;
  padding: 23px;
  background: linear-gradient(
    180deg,
    rgba(34, 35, 37, 0.2) 0%,
    rgba(34, 35, 37, 0) 100%
  );
  border: 1px solid #4f4f4f;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  /* border-top-right-radius: 8px; */
  overflow: hidden;
}
.card h5 {
  font-weight: 700;
  margin-block: 50px 15px;
}
.card p {
  max-width: 340px;
  font-size: 16px;
  margin-bottom: 15px;
}
.color {
  width: 46px;
  height: 46px;
  border-radius: 7px;
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: -2;
}
@media screen and (max-width: 1200px) {
  .heading {
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 576px) {
  .cards {
    grid-template-columns: repeat(1, 1fr);
    max-width: 272px;
    margin-inline: auto;
  }
}
