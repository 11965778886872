.heading {
  text-align: center;
  margin-bottom: 130px;
  font-weight: 600;
}
.leftPart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 750px;
}
.leftPartUp,
.leftPartDown {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  max-width: 436px;
}
.leftPartUp :nth-child(2)::after {
  content: url(../../../../Assets/images/circleVector.svg);
  position: absolute;
  right: -171px;
  top: 66px;
  overflow: hidden;
}
.leftPartDown :nth-child(2)::after {
  content: url(../../../../Assets/images/circleVector.svg);
  position: absolute;
  right: -171px;
  top: -9px;
  transform: scaleY(-1);
  overflow: hidden;
}
.leftPartDown img,
.leftPartUp img {
  width: 35px;
  height: 35px;
}
.leftPartDown h3,
.leftPartUp h3 {
  font-weight: 600;
  line-height: 39px;
  text-align: end;
}
.leftPartDown p,
.leftPartUp p {
  text-align: end;
  line-height: 115%;
  letter-spacing: 0.02em;
}
.rightPart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 750px;
}
.rightPartUp,
.rightPartDown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: relative;
  max-width: 436px;
}
.rightPartDown :nth-child(2)::after {
  content: url(../../../../Assets/images/circleVector.svg);
  position: absolute;
  left: -171px;
  top: -13px;
  transform: scale(-1);
  overflow: hidden;
}
.rightPartUp :nth-child(2)::after {
  content: url(../../../../Assets/images/circleVector.svg);
  position: absolute;
  left: -171px;

  top: 66px;

  transform: scaleX(-1);
  overflow: hidden;
}
.rightPartDown img,
.rightPartUp img {
  width: 35px;
  height: 35px;
}
.rightPartDown h3,
.rightPartUp h3 {
  font-weight: 600;
  line-height: 39px;
  text-align: start;
}
.rightPartDown p,
.rightPartUp p {
  text-align: start;
  line-height: 115%;
  letter-spacing: 0.02em;
}

.mainCircle {
  width: 334px;
  height: 334px;
  border-radius: 50%;
  background-image: url(../../../../Assets/images/middle.webp);
  background-size: cover;
}
.middlePart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 693px;
}
@media screen and (max-width: 1272px) {
  .middlePart {
    height: 673px;
  }
}

@media screen and (max-width: 1111px) {
  .middlePart {
    height: 647px;
  }
}
@media screen and (max-width: 1082px) {
  .rightPartDown :nth-child(2)::after {
    top: 10px;
  }
  .middlePart {
    height: 634px;
  }
}
@media screen and (max-width: 991px) {
  .rightPartUp :nth-child(2)::after,
  .rightPartDown :nth-child(2)::after,
  .leftPartUp :nth-child(2)::after,
  .leftPartDown :nth-child(2)::after {
    display: none;
  }
  .middlePart,
  .leftPart,
  .rightPart {
    height: auto;
    align-items: center;
    gap: 40px;
  }
  .leftPartUp,
  .leftPartDown,
  .rightPartUp,
  .rightPartDown {
    align-items: center;
  }
  .leftPartUp p,
  .leftPartDown p,
  .rightPartUp p,
  .rightPartDown p {
    text-align: center;
  }
  .leftPartUp h3,
  .leftPartDown h3,
  .rightPartUp h3,
  .rightPartDown h3 {
    text-align: center;
  }
}
@media screen and (max-width: 576px) {
  .mainCircle {
    width: 234px;
    height: 234px;
  }
}
