.subContainer {
  display: flex;
  flex-direction: column;
}
.info {
  display: flex;
  flex-direction: column;
  margin-bottom: 72px;
  gap: 20px;
}
.info span,
.subContainer li {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
.info :first-child {
  font-weight: 600;
}
.subContainer h3 {
  margin-block: 50px 20px;
  font-weight: 600;
}
.subContainer p,
.subContainer li {
  margin-bottom: 20px;
}

.numList li {
  margin-bottom: 0;
}

.subContainer li {
  list-style: inside;
  color: var(--text-color);
  padding-left: 30px;
  opacity: 0.8;
}
