.mainContainer {
  max-width: 1440px;
  height: 972px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 20px;
  gap: 15px;
  padding-bottom: 140px;
}
.mainContainer h1 {
  max-width: 813px;
  font-size: 76px;
}
.mainContainer p {
  max-width: 811px;
  margin-bottom: 25px;
}
@media screen and (max-width: 991px) {
  .mainContainer {
    margin-left: 0;
  }
}
@media screen and (max-width: 768px) {
  .mainContainer {
    align-items: center;
    text-align: center;
  }
  .mainContainer h1{
    font-size: 48px;
  }
}
