.alert {
    position: fixed;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 900px;
    padding: 0 20px;
    z-index: 999;
}
.content {
    background-color: #362535;
    border-radius: 0 20px 0 20px;
    padding: 50px 40px;
    border: 2px solid var(--button-border-color);
    display: flex;
    align-items: center;
    gap: 30px;
    font-size: 20px;
    color: white;
}

.content svg.alertIcon {
    font-size: 60px;
}

.close {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: 30px;
    cursor: pointer;
}
.close:hover {
    color: var(--button-border-color);
}

@media screen and (max-width: 768px) {
    .content {
        padding: 40px 20px;
        flex-direction: column;
        text-align: center;
    }
}
