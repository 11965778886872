.modal {
  position: fixed;
  z-index: 300;
  background: #151515;
  border-radius: 0px 50px;
  width: 558px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 70px 32px 56px 32px;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: scroll;
  max-height: 350px;
}
.content p {
  font-size: 20px;
  line-height: 24px;
}
.content h4 {
  font-weight: 600;
}
.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 70px;
}

.modal h3 {
  font-weight: 600;
  max-width: 180px;
  margin-inline: auto;
  margin-bottom: 40px;
}
.order {display: flex;
  flex-direction: column;
  gap: 15px;
  color: var(--text-color);
}

@media screen and (max-width: 600px) {
  .modal {
    width: 100vw;
    border-radius: 0;
  }
  .content {
    height: 300px;
  }
  .buttonContainer {
    margin-top: 30px;
  }
}
