.loader {
    height: 100vh;
    width: 100vw;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    overflow: hidden; background-color: black;
}
.defaultLoaderImage {
    max-width: 200px;
    max-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
