.mainContainer {
  position: relative;
}

/* dots on the left and right of the container's background */
.dotsRight {
  position: absolute;
  right: 0px;
  bottom: -180px;
  width: 140px;
  height: 348.94px;
  background: url("../../../../src/Assets/images/RightDotsContact.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.dotsLeft {
  position: absolute;
  left: 0px;
  top: -180px;
  z-index: -1;
  width: 157.89px;
  height: 348.94px;
  background: url("../../../../src/Assets/images/LeftDotsContact.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mainRow {
  background: url("../../../../src/Assets/images/contactBG.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 1132px;
  height: 393px;
  margin-inline: auto;
  border-radius: 15px;
}

/* left side of the container with VR image */
.left {
  background: url("../../../../src/Assets/images/vrContactImage.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  border-bottom-left-radius: 15px;
}

/* right side of the container with text and button */
.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.right h3 {
  background: radial-gradient(
    100% 97% at 100% 50%,
    #f9e3b0 13.03%,
    #ffc4ab 41.67%,
    #ecb4d0 87.06%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 600;
  max-width: 370px;
}
.right p {
  max-width: 564px;
  margin-block: 8px 30px;
}
.right span {
  -webkit-text-fill-color: black;
  -webkit-text-stroke: 4px transparent;
  -webkit-text-stroke-width: 0.03em;
}

@media screen and (max-width: 1200px) {
  .mainRow {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .left {
    display: none;
  }
  .right {
    align-items: center;
  }
  .right h3,
  .right p {
    margin-inline: auto;
    text-align: center;
  }
  .right h3 {
    max-width: 280px;
  }
  .right p {
    max-width: 360px;
  }
}
@media screen and (min-width: 769px) and (max-width: 991px) {
  .right h3{
    max-width: none;
  }
  .right p{
    max-width: 600px;
  }
}
@media screen and (max-width: 768px) {
  .dotsLeft,
  .dotsRight {
    display: none;
  }
}
