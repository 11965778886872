.mainContainer {
  position: relative;
}
.vectorLines {
  position: absolute;
  background: url("../../../../Assets/images/aboutBG.png") center no-repeat;
  background-size: cover;
  width: 320px;
  height: 600px;
  top: 300px;
  margin-left: -52px;
}
.left h4 {
  font-weight: 700;
  color: #a8a8a8;
  letter-spacing: -0.02em;
}
.left h2 {
  font-weight: 600;
  margin-block: 6px 25px;
}
.left p:nth-of-type(2) {
  margin-block: 18px 40px;
}
.meta {
  background: url("../../../../Assets/images/aboutPulseVR.png") center no-repeat;
  background-size: cover;
  height: 497px;
  border-radius: 15px 0 0 15px;
  display: flex;
  align-items: center;
  margin-inline: 150px -52px;
}
.cubeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 387px;
  height: 419px;

  padding: 20px;

  background: linear-gradient(
    180deg,
    rgba(34, 35, 37, 0.2) 0%,
    rgba(34, 35, 37, 0) 100%
  );
  border: 1px solid #4f4f4f;
  backdrop-filter: blur(6px);
  border-radius: 12px;

  margin-left: -150px;
}
.cubes {
  background: url("../../../../Assets/images/cubes.png") center no-repeat;
  background-size: cover;
  border-radius: 12px;
  width: 100%;
  height: 100%;
}
.bottomRow {
  margin-top: 80px;
}

.secondRow {
  display: flex;
  align-items: center;
  gap: 25px;
}

.secondRow h1 {
  font-weight: 600;
  font-size: 74px;
  line-height: 90px;

  background: radial-gradient(
    100% 97% at 100% 50%,
    #f9e3b0 13.03%,
    #ffc4ab 41.67%,
    #ecb4d0 87.06%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: black;
  -webkit-text-stroke: 4px transparent;
  -webkit-text-stroke-width: 0.03em;
}

.secondRow h4 {
  font-weight: 600;
  max-width: 239px;
}

@media screen and (max-width: 1200px) {
  .meta {
    margin-inline: 150px 0;
  }
  .left {
    text-align: center;
  }
  .vectorLines {
    top: 750px;
  }
  .secondRow{
    justify-content: center;
  }
}
@media screen and (max-width: 576px) {
  .cubeContainer {
    display: none;
  }
  .meta {
    margin-inline: 0;
    border-radius: 0;
    height: 350px;
  }
}
@media screen and (max-width: 350px) {
  .vectorLines {
    display: none;
  }
}
