.subContainer {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-inline: 52px;
}
.imageBackground {
  position: relative;
  background-image: url(../../../../Assets/images/Contact_BG.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 364px;
}
.leftCol {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image img {
  width: 100%;
}

.rightCol {
  display: flex;
  align-items: center;
  justify-content: center;
}
.textContainer {
  padding-left: 75px;
}
.textContainer h1 {
  max-width: 739px;
  margin-bottom: 10px;
}
.textContainer span {
  font-family: "Raleway", sans-serif;
  color: transparent;
  -webkit-text-stroke-color: var(--text-color);
  -webkit-text-stroke-width: 0.03em;
  font-weight: bolder;
}

@media screen and (max-width: 1119px) {
  .subContainer {
    padding-inline: 15px;
  }
}
@media screen and (max-width: 991px) {
  .textContainer p {
    max-width: 613px;
    margin: auto;
  }
  .subContainer {
    padding-inline: 15px;
  }
  .textContainer {
    text-align: center;
    padding-inline: 15px;
  }
  .imageBackground {
    /* background-image: url(../../../../Assets/images/Contact_SM.png); */
    height: 664px;
  }
}
@media screen and (max-width: 576px) {
  .image {
    display: none;
  }
  .imageBackground {
    background-image: url(../../../../Assets/images/contact-sm.webp);
  }
}
