.mainContainer {
  position: relative;
}

/* background dots at the edge of the Container i.e the whole component */
.dots {
  position: absolute;
  right: 0px;
  top: -280px;
  width: 157.89px;
  height: 348.94px;
  background: url("../../../../Assets/images/tradeDots.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.heading {
  text-align: center;
  margin-bottom: 130px;
  font-weight: 600;
}
.cards {
  display: flex;
  gap: 30px;
}
.cardContainer {
  position: relative;
  width: 100%;
}

/* box/div with color behind each card */
.color {
  width: 70px;
  height: 70px;
  border-radius: 23px;
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: -2;
}

/* image container for each card */
.cardImage {
  position: absolute;
  top: -45px;
  left: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 220px;
  height: 220px;
  z-index: 2;
}

/* Card Body and all text */
.cardBody {
  clip-path: polygon(85% 0%, 100% 15%, 100% 100%, 0% 100%, 0% 0%);
  width: 100%;
  height: 500px;
  border-radius: 20px;
  position: relative;
  padding: 30px;
  background: linear-gradient(
    180deg,
    rgba(34, 35, 37, 0.2) 0%,
    rgba(34, 35, 37, 0) 100%
  );
  border: 1px solid #4f4f4f;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  border-top-right-radius: 78px;
  overflow: hidden;
}
.cardBody h4 {
  margin-block: 180px 15px;
  font-weight: 700;
}
.cardBody p {
  max-width: 340px;
  line-height: 22px;
}

@media screen and (max-width: 1024px) {
  .cards {
    flex-direction: column;
    gap: 100px;
  }
  .cardContainer {
    max-width: 420px;
    margin-inline: auto;
  }
}
@media screen and (max-width: 768px) {
  .dots {
    display: none;
  }
  .cardBody p {
    font-size: 18px;
    line-height: 22px;
  }
}
