.container {
  padding-inline: 0px !important;
}
.heroCenter {
  padding-inline: 0px !important;
  padding-block: 130px;
  padding-block-end: 0;
  position: relative;
  background-image: url(../../Assets/images/ECO_BG.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.contactUs,
.circleServices,
.contactUs,
.newWay,
.whatIs,
.tokenommics,
.gateway {
  margin-bottom: 200px;
}

@media screen and (max-width: 991px) {
  .contactUs,
  .circleServices,
  .contactUs,
  .newWay,
  .whatIs,
  .tokenommics,
  .gateway {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 576px) {
  .heroCenter {
    background-image: url(../../Assets/images/eco-sm.webp);
  }
}
