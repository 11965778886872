.subContainer {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-inline: 52px;
}
.leftCol {
  display: block;
}
.image {
  display: flex;
  max-width: 500px;
  height: 500px;
  margin-bottom: 70px;
}
.image img {
  width: 100%;
}

.rightCol {
  display: flex;
  align-items: center;
  justify-content: center;
}
.textContainer {
  margin-bottom: 80px;
}
.textContainer h1 {
  max-width: 739px;
  margin-bottom: 10px;
}
.textContainer span {
  font-family: "Raleway", sans-serif;
  color: transparent;
  -webkit-text-stroke-color: var(--text-color);
  -webkit-text-stroke-width: 0.03em;
  font-weight: bolder;
}

@media screen and (max-width: 991px) {
  .image img {
    max-width: 400px;
    margin: auto;
  }
  .textContainer p {
    max-width: 613px;
    margin: auto;
  }
  .subContainer {
    padding-inline: 15px;
  }
  .textContainer {
    text-align: center;
  }
  .leftCol {
    display: none;
  }
}
