.mainCotainer {
  position: relative;
  z-index: 4;
}

.leftContainer {
  display: flex;
  flex-direction: column;
}

.leftContainer h2 {
  margin-bottom: 60px;
  max-width: 671px;
}
.imageContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 44;
}
.imageContainer h1 {
  color: black;
  background: -webkit-linear-gradient(
    264deg,
    #f9e3b0 13.03%,
    #ffc4ab 41.67%,
    #7b8689 87.06%
  );
  -webkit-background-clip: text;
  -webkit-text-stroke: 4px transparent;
  -webkit-text-stroke-width: 0.03em;
  font-weight: bolder;
}
.imageContainer img {
  width: 100%;
  display: flex;
  position: relative;
  z-index: 44;
  margin-block: auto;
}

.contentContainer {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  margin-bottom: 60px;
  align-items: flex-start;
}

.information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 31px;
}

.information h3 {
  margin-bottom: 12px;
}

.information p {
  margin-bottom: 0;
  max-width: 426px;
}
.button {
  display: flex;
  margin-left: 61px;
}
@media screen and (max-width: 991px) {
  .imageContainer img {
    max-width: 500px;
  }
}
@media screen and (max-width: 768px) {
  .contactFormContainer {
    padding: 10px;
  }
}

@media screen and (max-width: 576px) {
  .button {
    margin: auto;
  }
}
