.leftContainer {
  display: flex;
  flex-direction: column;
}

.leftContainer h2 {
  margin-bottom: 60px;
  max-width: 671px;
  font-weight: 600;
}
.contentContainer {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  margin-bottom: 60px;
  align-items: flex-start;
}
.content img {
  margin-top: 6px;
}
.information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 31px;
}

.information h3 {
  margin-bottom: 12px;
}

.information p {
  margin-bottom: 0;
  max-width: 426px;
}
.button {
  display: flex;
  margin-left: 61px;
}

.rightContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.imageContainer {
  background: url("../../../../../src/Assets/images/coinandVR.png") left
    no-repeat;
  background-size: contain;
  width: 100%;
  height: 296px;
  position: relative;
}
.cards {
  display: flex;
  flex-direction: column;
  gap: 55px;
}
.card {
  display: flex;
  align-items: center;

  gap: 15px;
  background: linear-gradient(
    180deg,
    rgba(34, 35, 37, 0.2) 0%,
    rgba(34, 35, 37, 0) 100%
  );
  border: 1px solid #4f4f4f;
  backdrop-filter: blur(6px);
  border-radius: 10px;

  width: 272px;
  height: 96px;
}
.eclipse,
.waves,
.card p {
  transform: translateX(-32px);
}
.eclipse {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.waves {
  background: url("../../../../../src/Assets/images/waves.png") center no-repeat;
  background-size: cover;
  width: 15px;
  height: 46px;
}
.card p {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  text-transform: uppercase;
}
.card:nth-child(1),
.card:nth-child(5) {
  transform: translateX(-200px);
}
.card:nth-child(2),
.card:nth-child(4) {
  transform: translateX(-100px);
}

@media screen and (max-width: 991px) {
  .card {
    transform: translateX(0px);
  }
}

@media screen and (max-width: 576px) {
  .imageContainer {
    display: none;
  }
  .card:nth-child(odd),
  .card:nth-child(even) {
    transform: translateX(0);
  }
  .cards {
    width: 100%;
    align-items: center;
  }
  .card {
    width: 230px;
  }
  .button {
    margin: auto;
  }
}
