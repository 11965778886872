.container {
  padding-inline: 0px !important;
}
.heroCenter {
  padding-inline: 0px !important;
  padding-block: 165px;
  padding-block-end: 0;
  position: relative;
  background-image: url(../../Assets/images/ABT_BG.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.aboutPulse {
  margin-top: 200px;
}
.team,
.values,
.contactUs,
.aboutPulse,
.faq,
.partners {
  margin-bottom: 200px;
}
@media screen and (max-width: 991px) {
  .team,
  .values,
  .contactUs,
  .aboutPulse,
  .faq,
  .partners,
  .roadmap {
    margin-bottom: 80px;
  }
  .aboutPulse {
    margin-top: 80px;
  }
}
@media screen and (max-width: 576px) {
  .heroCenter {
    background-image: url(../../Assets/images/ABT_SM.webp);
  }
}
