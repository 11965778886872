.container {
  padding-inline: 0px !important;
}
.heroCenter {
  padding-inline: 0px !important;
  padding-block: 130px;
  padding-block-end: 0;
}
.bodyTerm,
.heroCenter {
  margin-bottom: 88px;
}
