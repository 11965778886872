.mainContainer {
  height: 1622px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mainContainer h3 {
  margin-top: 20px;
  margin-bottom: 100px;
}

.roadBox {
  position: relative;
  left: -53px;
}
.roadBoxMobile {
  display: none;
}

.secondPoint {
  padding: 0;
  width: 267px;
  position: absolute;
  top: 14%;
  left: -82%;
}
.thirdPoint {
  padding: 0;
  width: 267px;
  position: absolute;
  top: 27.6%;
  left: 108%;
}
.fourthPoint {
  padding: 0;
  width: 267px;
  position: absolute;
  top: 37.3%;
  left: -125%;
}
.fifthPoint {
  padding: 0;
  width: 267px;
  position: absolute;
  top: 51%;
  left: 124%;
}
.sixthPoint {
  padding: 0;
  width: 267px;
  position: absolute;
  top: 60.5%;
  left: -125%;
}
.seventhPoint {
  padding: 0;
  width: 267px;
  position: absolute;
  top: 75%;
  left: 92%;
}

.thirdPoint .cards :first-child::after,
.fifthPoint .cards :first-child::after,
.seventhPoint .cards :first-child::after {
  content: "";
  border-left: 75px solid rgba(255, 255, 255, 0.1);
  width: 75px;
  position: absolute;
  height: 2px;
  right: 200px;
}
.thirdPoint .cards :first-child::after {
  top: 70px;
}
.fifthPoint .cards :first-child::after {
  top: 126px;
}

.seventhPoint .cards :first-child::after {
  top: 79px;
}
.secondPoint .cards :first-child::after,
.fourthPoint .cards :first-child::after,
.sixthPoint .cards :first-child::after {
  content: "";
  border-left: 75px solid rgba(255, 255, 255, 0.1);
  width: 75px;
  position: absolute;
  height: 2px;
  right: -153px;
}
.secondPoint .cards :first-child::after {
  top: 25px;
}
.fourthPoint .cards :first-child::after {
  border-left: 141px solid rgba(255, 255, 255, 0.1);
  width: 75px;
  position: absolute;
  height: 2px;
  right: -217px;
  top: 100px;
}
.sixthPoint .cards :first-child::after {
  top: 76px;
}
.cards {
  max-width: 363px;

  padding: 10px;
  background: black;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  font-weight: 500;
  font-size: 15px;
}
.cards h5 {
  margin-bottom: 6px;
}
@media screen and (max-width: 991px) {
  .roadBox {
    display: none;
  }
  .roadBoxMobile {
    display: flex;
    height: 100%;
    width: 100%;
    background-image: url(../../../../Assets/images/signal.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    gap: 100px;
  }
}
