.mainContainer {
  position: relative;
}

/* background dots on the right of the container */
.dotsRight {
  position: absolute;
  right: 0px;
  top: -120px;
  width: 157px;
  height: 348px;
  background: url("../../../../../src/Assets/images/RightDotsContact.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

/* background dots on the left of the container */
.dotsLeft {
  position: absolute;
  left: 0px;
  bottom: -270px;
  z-index: -1;
  width: 157px;
  height: 348px;
  background: url("../../../../../src/Assets/images/LeftDotsContact.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.heading {
  text-align: center;
  margin-bottom: 75px;
  font-weight: 600;
}
.cards {
  display: flex;
  gap: 30px;
  justify-content: center;
}
.singleCardOne,
.singleCardTwo {
  position: relative;
  width: 558px;
  height: 402px;
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  text-align: center;
  border-radius: 10px;
}

.singleCardOne:hover .textContent,
.singleCardTwo:hover .textContent {
  border: 1px solid #757373;
  margin-inline: 20px;
  padding: 10px;
  background: #04040469;
  border-radius: 8px;
  height: 160px;
}

.cardOne,
.cardTwo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: transparent;
  z-index: -2;
  background-size: cover;
}
.cardOne {
  background: url("../../../../../src/Assets/images/creatingMaetaBG1.png")
    center no-repeat;
}
.cardTwo {
  background: url("../../../../../src/Assets/images/creatingMetaBG2.png") center
    no-repeat;
}

.singleCardOne p,
.singleCardTwo p {
  margin-block: 25px 35px;
  max-width: 462px;
  margin-inline: auto;
}

.textContent {
  transform: translateY(75px);
  transition: 0.5s;
  height: 125px;
}

/* animation to be added on hover of each card, handled by JS */
.move {
  transform: translateY(-30px);
  transition: 0.5s;
}

.textContent h5 {
  font-weight: 700;
  color: #ffffff;
}
.textContent p {
  color: #ffffff;
  margin-top: 20px;
}

@media screen and (max-width: 1042px) {
  .cards {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 768px) {
  .dotsLeft,
  .dotsRight {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .singleCardOne,
  .singleCardTwo {
    width: 100%;
  }
  .textContent h5 {
    margin-inline: auto;
  }
  .move {
    transform: translateY(-50px);
    transition: 0.5s;
  }
}
@media screen and (max-width: 450px) {
  
.singleCardOne:hover .textContent,
.singleCardTwo:hover .textContent {
  margin-inline: 5px;
  height: 210px;
}
}
