.mainContainer {
  max-width: 1170px;
  margin-inline: auto;
}
.left {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
}

.cardContainer {
  position: relative;
  width: 100%;
}
.card {
  clip-path: polygon(90% 0%, 100% 30%, 100% 100%, 0% 100%, 0% 0%);
  width: 100%;
  border-radius: 24px;
  position: relative;
  padding: 25px;
  background: #151515;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  overflow: hidden;
}
.color {
  width: 70px;
  height: 70px;
  border-radius: 23px;
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: -2;
}
.card h4 {
  text-transform: capitalize;
  margin-block: 40px 10px;
}
.card h2 {
  font-weight: 600;
}
.tag {
  background: rgba(235, 91, 229, 0.05);
  border-radius: 119px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 28px;
}
.tag p {
  font-weight: 800;
  font-size: 10px;
  line-height: 12px;
}

.vision,
.mission {
  flex-direction: column;
  justify-content: center;
  gap: 18px;
  height: 100%;
}

.vision h4,
.mission h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: -0.02em;
  color: #a8a8a8;
}
.mission h2,
.vision h2 {
  font-weight: 600;
  max-width: 400px;
}
.mission p,
.vision p {
  margin-bottom: 60px;
  max-width: 538px;
}
@media screen and (max-width: 991px) {
  .cardContainer {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .left {
    width: 100%;
  }

  .mission,
  .vision {
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 576px) {
  .mainContainer {
    flex-direction: column-reverse;
  }

  .mission p,
  .vision p {
    margin-bottom: 40px;
  }

  .mainContainer {
    align-items: flex-start;
  }
}
