.mainContainer h3 {
  font-weight: 600;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 100px;
}
.teamGrid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 50px;
  max-width: 1168px;
  margin-inline: auto;
  justify-items: center;
}
.cardContainer {
  position: relative;
  width: 346px;
}
.card {
  clip-path: polygon(85% 0%, 100% 15%, 100% 100%, 0% 100%, 0% 0%);
  width: 100%;
  border-radius: 24px;
  position: relative;
  padding: 10px;
  background: #151515;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  /* border-top-right-radius: 78px; */
}
.color {
  width: 70px;
  height: 70px;
  border-radius: 23px;
  position: absolute;
  right: 6px;
  top: 6px;
  z-index: -2;
}
.card p {
  margin-block: 5px;
  font-size: 16px;
  line-height: 19px;
  color: #5e6b7e;
}
.card h4 {
  font-weight: 700;
  margin-block: 40px 0;
}
.card h4,
.card p {
  margin-left: 22px;
}
.image {
  width: 100%;
  height: 290px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 24px;
  /* border-top-right-radius: 78px; */
  clip-path: polygon(85% 0%, 100% 21%, 100% 100%, 0% 100%, 0% 0%);
}

.linkedin {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid #5e6b7e;
  margin-left: auto;
  margin-right: 22px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5e6b7e;
  transition: all 0.2s;
}

.linkedin:hover {
  background-color: #0077b5;
  color: #fff;
  cursor: pointer;
  border-color: #0077b5;
}

@media screen and (max-width: 991px) {
  .teamGrid {
    grid-template-columns: repeat(1, 1fr);
    /* max-width: 356px; */
  }
}
@media screen and (max-width: 991px) {
}
@media screen and (max-width: 370px) {
  .card {
    padding-inline: 10px;
  }
  .cardContainer{
    width: 100%;
  }
}
