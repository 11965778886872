.leftSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
}
.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-content: center;
}
.heading h3 {
  font-family: "Raleway", sans-serif;
  color: black;
  background: -webkit-linear-gradient(
    264deg,
    #f9e3b0 13.03%,
    #ffc4ab 41.67%,
    #7b8689 87.06%
  );
  -webkit-background-clip: text;
  -webkit-text-stroke: 4px transparent;
  -webkit-text-stroke-width: 0.03em;
  font-weight: bolder;
  margin-bottom: 20px;
}
.heading h2 {
  font-weight: 600;
  margin-bottom: 100px;
  max-width: 450px;
}

.rightSection {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.rightSection h4 {
  font-weight: 400;
  margin-bottom: 25px;
}
.titleIcon {
  display: flex;
  align-items: flex-start;
  gap: 25px;
  margin-bottom: 55px;
}
.titleIcon h6 {
  margin-bottom: 25px;
}
.inputField {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  position: relative;
}
.inputField h6 {
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  color: var(--text-color);
}
.inputField input,
.inputField textarea {
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 16px;
  border-radius: 1px;
  background-color: transparent;
  color: var(--text-color);
}
.inputField input:focus,
.inputField textarea:focus {
  outline: none;
}

.inputField textarea {
  resize: vertical;
}
.formContiner {
  width: 100%;
  height: 100%;
}
.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.formMessage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 100;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}
@media screen and (max-width: 991px) {
  .formContiner {
    padding-inline: 120px;
  }
  .rightSection {
    max-width: 237px;
    margin-inline: auto;
  }
}
@media screen and (max-width: 576px) {
  .formContiner {
    padding-inline: 0px;
  }
}
