.container {
  padding-inline: 52px;
}
.buttonStyle {
  position: relative;
  display: inline-block;
  background: var(--button-color);
  color: #ffff;
  border: none;
  transition: all 0.3s ease;
  padding: 10px 32px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  border-radius: 3px 2px 3px 3px;
}
.buttonStyle::before {
  content: "";
  position: absolute;
  border: 2px solid var(--button-border-color);
  box-sizing: content-box;
  width: 100%;
  height: 94%;
  bottom: 0px;
  left: 0px;
  border-radius: 2px;
  clip-path: polygon(0 0, calc(100% - 16px) 0, 100% 40%, 100% 100%, 0 100%);
}
.buttonStyle::after {
  content: "";
  position: absolute;
  border-right: 2px solid var(--button-border-color);
  box-sizing: content-box;
  height: 23px;
  bottom: 21px;
  left: calc(100% - 5px);
  border-radius: 2px;
  transform: rotate(138deg);
  border-radius: 2px;
}

.buttonStyle:hover {
  background: var(--text-color);
  color: var(--main-color);
}

.inputFieldStyle input {
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 16px;
  border-radius: 1px;
  background-color: transparent;
  color: var(--text-color);
}
.inputFieldStyle input:focus {
  outline: none;
}
.inputFieldErrorStyle input {
  border: 1px solid rgba(255, 28, 28, 0.733);
  padding: 16px;
  border-radius: 1px;
  background-color: transparent;
  color: var(--text-color);
}
.inputFieldLabelStyle {
  font-weight: 500;
  line-height: 24px;
  color: var(--text-color);
  padding-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .container {
    padding-inline: 15px;
  }
}
