.mainContainer {
	background: #040404;
	padding-block: 50px 30px;
}
.logoContainer {
	width: 231px;
	height: 32px;
}
.logoContainer img {
	width: 100%;
}
.footerTop p {
	max-width: 429px;
	margin-block: 40px 0;
}
.footerTop h5 {
	margin-block: 10px 25px;
	font-weight: 600;
}

/* links to naigate the website */
.footerTop li {
	font-family: 'Poppins';
	margin-block: 13px 0;
	font-size: 18px;
	line-height: 27px;
	color: #d4d4d4;
}
.footerTop li a:hover {
	color: var(--button-border-color);
}
.footerTop li a {
	color: #d4d4d4;
}

/* division line between bottom and top of footer  */
.separator {
	margin-inline: auto;
	max-width: 1260px;
	opacity: 0.5;
	border-bottom: 1.05px solid #6b6b6b;
	margin-block: 35px 25px;
}

/* socialMedia icons  */
.icons {
	display: flex;
	justify-content: space-between;
	margin-block: 20px 0;
}
.icons a {
	width: 40px;
	height: 40px;
}
.icons a svg {
	width: 100%;
	height: 100%;
	padding: 8px;
	font-size: 20px;
	color: white;
	border-radius: 50%;
	border: 1px solid #ffffff;
	transition: all 0.3s;
}
.icons a svg:hover {
	scale: 1.4;
}
.facebookIcon:hover {
	background-color: #3b5998;
	border: 1px solid #3b5998;
}
.instaIcon:hover {
	background: radial-gradient(
			circle farthest-corner at 35% 90%,
			#fec564,
			transparent 50%
		),
		radial-gradient(
			circle farthest-corner at 0 140%,
			#fec564,
			transparent 50%
		),
		radial-gradient(
			ellipse farthest-corner at 0 -25%,
			#5258cf,
			transparent 50%
		),
		radial-gradient(
			ellipse farthest-corner at 20% -50%,
			#5258cf,
			transparent 50%
		),
		radial-gradient(
			ellipse farthest-corner at 100% 0,
			#893dc2,
			transparent 50%
		),
		radial-gradient(
			ellipse farthest-corner at 60% -20%,
			#893dc2,
			transparent 50%
		),
		radial-gradient(
			ellipse farthest-corner at 100% 100%,
			#d9317a,
			transparent
		),
		linear-gradient(
			#6559ca,
			#bc318f 30%,
			#e33f5f 50%,
			#f77638 70%,
			#fec66d 100%
		);
	border: none;
}

.twitterIcon:hover {
	color: #1da1f2;
	background-color: #fff;
}
.linKedinIcon:hover {
	color: #0e76a8;
	background-color: #fff;
}
.youtubeIcon:hover {
	color: red;
	background-color: #fff;
}

/* text below separator  */
.footerBottom h6 {
	text-align: center;
	font-family: 'Poppins';
	font-size: 13.65px;
	line-height: 21px;
}

.year {
	font-weight: 600;
	color: #fff;
}

@media screen and (max-width: 991px) {
	.footerTop p {
		max-width: none;
	}
}
@media screen and (max-width: 767px) {
	.icons {
		justify-content: flex-start;
		gap: 30px;
		flex-wrap: wrap;
	}
}
