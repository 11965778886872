.subContainer {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.textContainer {
  margin-bottom: 80px;
  text-align: center;
}

.textContainer span {
  font-family: "Raleway", sans-serif;
  color: transparent;
  -webkit-text-stroke-color: var(--text-color);
  -webkit-text-stroke-width: 0.03em;
  font-weight: bolder;
}
.textContainer p {
  margin: auto;
  max-width: 613px;
}
.image {
  display: flex;
  max-width: 500px;

  margin-bottom: 70px;
}
.image img {
  width: 100%;
}
@media screen and (max-width: 991px) {
  .image img {
    max-width: 400px;
    margin: auto;
  }
  .image {
    margin-bottom: 0px;
  }
}
