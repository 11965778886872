.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--height-navbar);
  position: absolute;
  width: 100%;
  z-index: 99;
}
.mainContainer {
  width: 100%;
}
.content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navBarLogo img {
  max-height: 45px;
}

.navBarMenu {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
  margin-top: 5px;
  padding: 0;
}

.navBarMenuItem,
.navBarMenuItemActive {
  margin: 0 22px;
  position: relative;
}

.navBarMenuItem::after {
  content: "";
  height: 3px;
  position: absolute;
  bottom: -10px;
  left: 25%;
  background: radial-gradient(
    100% 97% at 100% 50%,
    #f9e3b0 13.03%,
    #ffc4ab 41.67%,
    #ecb4d0 87.06%
  );
  border-radius: 9px;
  border-radius: 10px;
  width: 0;
  transition: all 0.3s;
}
.navBarMenuItemActive::after,
.navBarMenuItem:hover::after {
  content: "";
  height: 3px;
  position: absolute;
  bottom: -10px;
  left: 23%;
  background: radial-gradient(
    100% 97% at 100% 50%,
    #f9e3b0 13.03%,
    #ffc4ab 41.67%,
    #ecb4d0 87.06%
  );
  border-radius: 9px;
  border-radius: 10px;
  width: 50%;
  transition: all 0.5s;
}

.navBarMenuLink {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.8);
}
.navBarMenuLinkActive {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #ffffff;
}
.navBarIcon {
  display: none;
}
.iconPartOne {
  width: 30px;
  height: 3px;
  border-radius: 10px;
  background: radial-gradient(
    100% 97% at 100% 50%,
    #f9e3b0 13.03%,
    #ffc4ab 41.67%,
    #ecb4d0 87.06%
  );
  margin-bottom: 7px;
  transition: all 0.3s;
}

.navBarIconMobile.active .iconPartOne {
  width: 30px;
  transform: translateY(10px) rotate(-45deg);
}

.iconPartTwo {
  width: 22px;
  height: 3px;
  border-radius: 10px;
  background: radial-gradient(
    100% 97% at 100% 50%,
    #f9e3b0 13.03%,
    #ffc4ab 41.67%,
    #ecb4d0 87.06%
  );
  opacity: 1;
  transition: all 0.3s;
}

.navBarIconMobile.active .iconPartTwo {
  opacity: 0;
}

.iconPartThree {
  width: 14px;
  height: 3px;
  border-radius: 10px;
  background: radial-gradient(
    100% 97% at 100% 50%,
    #f9e3b0 13.03%,
    #ffc4ab 41.67%,
    #ecb4d0 87.06%
  );
  margin-top: 7px;
  transition: all 0.3s;
}

.navBarIconMobile.active .iconPartThree {
  width: 30px;
  transform: translateY(-10px) rotate(45deg);
}

/* NavbarMenu Component */
.navBarMenu {
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
  margin-top: 5px;
  padding: 0;
}
.navBarMenuItem {
  margin: 0 22px;
}

/* MobileMenu Component */
.mobileleft {
  display: none;
}
.mobileMenu {
  display: none;
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transform: translateX(100%);
  transition: all 0.6s;
  background-image: url("../../../Assets/images/mobilleBackground.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.mobileMenu nav {
  margin-top: 20px;
  height: calc(100% - 95px);
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.navBarMenuMobile {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.menuIconsContainer {
  text-align: center;
  flex-grow: 1;
  margin-bottom: 41px;
}

.menuIconsContainer .menuIcon {
  height: 30px;
  margin: 0 20px;
}

.mobileMenuOpen {
  transform: translateX(0);
}
@media screen and (max-width: 991px) {
  .navBarMenuItem,
  .navBarMenuItemActive {
    margin: 0 12px;
    position: relative;
  }
}

@media screen and (max-width: 900px) {
  .navLinksWrapper {
    display: none;
  }
  .bodyScrollDisabled {
    overflow: hidden;
  }
  .navBarMenu {
    display: none;
  }

  .buttons {
    display: none;
  }
  .navBarIconMobile {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    height: 100%;
    width: 30px;
    text-align: right;
    cursor: pointer;
    z-index: 110;
  }
  .navBarLogo {
    position: relative;
    z-index: 110;
    max-height: 35px;
  }

  .navBarLogo img {
    max-height: 35px;
  }
  .mobileMenu {
    display: block;
  }
  .navBarIcon {
    display: flex;
  }
}
