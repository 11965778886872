@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap");

:root {
  /* Read variables From dark to light */

  --transition-theme: 0.5s;
  --main: #87276c;
  --secondary-color: #029166;
  --button-color: #362535;
  --button-border-color: #eb5be5;
  --button-disabled-color: #39aca6ba;
  --button-hover-color: #2e908b;
  --text-color: #dddddd;
  --secondary-text-color: #6f767e;
  --linear-text-color: radial-gradient(
    100% 97% at 100% 50%,
    #f9e3b0 13.03%,
    #ffc4ab 41.67%,
    #ecb4d0 87.06%
  );

  /* *NEW* */

  --height-navbar: 90px;
  --border-radius-min: 5px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: var(--main);
}

/* --------------- SCROLLBAR */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-clip: content-box;
  background-color: rgba(0, 0, 0, 0);
  width: 2px;
}

::-webkit-scrollbar-thumb {
  border: 1px solid transparent;
  background-color: var(--main);
  border-radius: 2px;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--main) rgba(255, 255, 255, 0) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  background-color: black;
}

a {
  text-decoration: none;
  color: var(--main);
}

h1 {
  font-weight: bolder;
  color: var(--text-color);
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 100;
  margin-bottom: 0;
  color: var(--text-color);
}
p {
  color: var(--text-color);
  opacity: 0.8;
  font-family: "Inter", sans-serif;
}

h1 {
  font-size: 64px;
  line-height: 78px;
}

h2 {
  font-size: 40px;
  line-height: 49px;
}

h3 {
  font-size: 32px;
  line-height: 39px;
}
h4 {
  font-size: 24px;
  line-height: 29px;
}
h5 {
  font-size: 20px;
  line-height: 24px;
}
h6 {
  font-size: 20px;
  line-height: 24px;
}

p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 36px;
    line-height: 50px;
  }

  /* h1 =h2-4 */
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 20px;
  }
  p {
    font-size: 14px;
  }
}
